@font-face {
  font-family: 'icomoon';
  src:  url('../icons/icomoon.eot?crf2yw');
  src:  url('../icons/icomoon.eot?crf2yw#iefix') format('embedded-opentype'),
    url('../icons/icomoon.ttf?crf2yw') format('truetype'),
    url('../icons/icomoon.woff?crf2yw') format('woff'),
    url('../icons/icomoon.svg?crf2yw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chrome:before {
  content: "\e900";
}
.icon-dj:before {
  content: "\e901";
}
.icon-edge:before {
  content: "\e902";
}
.icon-firefox:before {
  content: "\e903";
}
.icon-hat:before {
  content: "\e904";
}
.icon-mixer:before {
  content: "\e905";
}
.icon-music:before {
  content: "\e906";
}
.icon-opera:before {
  content: "\e907";
}
.icon-record:before {
  content: "\e908";
}
.icon-safari:before {
  content: "\e909";
}
.icon-sound:before {
  content: "\e90a";
}
.icon-web:before {
  content: "\e90b";
}
