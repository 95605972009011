// primary button bg
$primary-dark-bg: #02242F;
$primary-light-bg: #054A5C;
@mixin btn-primary-bg () {
	background: -moz-linear-gradient(90deg, $primary-light-bg 0%, $primary-dark-bg 100%); /* ff3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $primary-dark-bg), color-stop(100%, $primary-light-bg)); /* safari4+,chrome */
	background: -webkit-linear-gradient(90deg, $primary-light-bg 0%, $primary-dark-bg 100%); /* safari5.1+,chrome10+ */
	background: -o-linear-gradient(90deg, $primary-light-bg 0%, $primary-dark-bg 100%); /* opera 11.10+ */
	background: -ms-linear-gradient(90deg, $primary-light-bg 0%, $primary-dark-bg 100%); /* ie10+ */
	background: linear-gradient(0deg, $primary-light-bg 0%, $primary-dark-bg 100%); /* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02242F', endColorstr='#054A5C',GradientType=0 ); /* ie6-9 */
}

$primary-dark-hover-bg: darken($primary-dark-bg, 3%);
$primary-light-hover-bg: darken($primary-light-bg, 3%);
@mixin btn-primary-darker-bg () {
	background: -moz-linear-gradient(90deg, $primary-light-hover-bg 0%, $primary-dark-hover-bg 100%); /* ff3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $primary-dark-hover-bg), color-stop(100%, $primary-light-hover-bg)); /* safari4+,chrome */
	background: -webkit-linear-gradient(90deg, $primary-light-hover-bg 0%, $primary-dark-hover-bg 100%); /* safari5.1+,chrome10+ */
	background: -o-linear-gradient(90deg, $primary-light-hover-bg 0%, $primary-dark-hover-bg 100%); /* opera 11.10+ */
	background: -ms-linear-gradient(90deg, $primary-light-hover-bg 0%, $primary-dark-hover-bg 100%); /* ie10+ */
	background: linear-gradient(0deg, $primary-light-hover-bg 0%, $primary-dark-hover-bg 100%); /* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02242F', endColorstr='#054A5C',GradientType=0 ); /* ie6-9 */
}

@mixin radial-basic-bg () {
	background: -moz-radial-gradient(top, ellipse cover, rgba(70,70,70,1) 0%, rgba(34,34,34,1) 100%); /* ff3.6+ */
	background: -webkit-gradient(radial, top center center, 0px, center center, 100%, color-stop(0%, rgba(70,70,70,1)), color-stop(100%, rgba(34,34,34,1))); /* safari4+,chrome */
	background:-webkit-radial-gradient(top, ellipse cover, rgba(70,70,70,1) 0%, rgba(34,34,34,1) 100%); /* safari5.1+,chrome10+ */
	background: -o-radial-gradient(top, ellipse cover, rgba(70,70,70,1) 0%, rgba(34,34,34,1) 100%); /* opera 11.10+ */
	background: -ms-radial-gradient(top, ellipse cover, rgba(70,70,70,1) 0%, rgba(34,34,34,1) 100%); /* ie10+ */
	background:radial-gradient(ellipse at top, rgba(70,70,70,1) 0%, rgba(34,34,34,1) 100%); /* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#464646', endColorstr='#222222',GradientType=1 ); /* ie6-9 */
}

@mixin radial-recomended-bg () {
	background: -moz-radial-gradient(top, ellipse cover, rgba(30,218,204,1) 0%, rgba(13,179,157,1) 100%); /* ff3.6+ */
	background: -webkit-gradient(radial, top center center, 0px, center center, 100%, color-stop(0%, rgba(30,218,204,1)), color-stop(100%, rgba(13,179,157,1))); /* safari4+,chrome */
	background:-webkit-radial-gradient(top, ellipse cover, rgba(30,218,204,1) 0%, rgba(13,179,157,1) 100%); /* safari5.1+,chrome10+ */
	background: -o-radial-gradient(top, ellipse cover, rgba(30,218,204,1) 0%, rgba(13,179,157,1) 100%); /* opera 11.10+ */
	background: -ms-radial-gradient(top, ellipse cover, rgba(30,218,204,1) 0%, rgba(13,179,157,1) 100%); /* ie10+ */
	background:radial-gradient(ellipse at top, rgba(30,218,204,1) 0%, rgba(13,179,157,1) 100%); /* w3c */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1EDACC', endColorstr='#0DB39D',GradientType=1 ); /* ie6-9 */
}

@mixin transition ($time) {
	transition: all $time ease;
}

@mixin transition-delay ($delay) {
	transition-delay: $delay;
	-webkit-transition-delay: $delay;
}

// content animations
@mixin top-to-bottom-fade () {
	top: 0;
	opacity: 1;
}

@mixin left-to-right-fade () {
	left: 0;
	opacity: 1;
}

@mixin right-to-left-fade () {
	right: 0;
	opacity: 1;
}

@mixin bottom-to-top-fade () {
	bottom: 0;
	opacity: 1;
}
