@import "./scss/functions";
@import "./variables";
@import "./mixins";
@import "./icons";
@import "./scss/mixins";
@import "./scss/root";
@import "./scss/reboot";
@import "./scss/type";
@import "./scss/images";
@import "./scss/code";
@import "./scss/grid";
@import "./scss/tables";
@import "./scss/forms";
@import "./scss/buttons";
@import "./scss/transitions";
@import "./scss/dropdown";
@import "./scss/button-group";
@import "./scss/input-group";
@import "./scss/custom-forms";
@import "./scss/nav";
@import "./scss/navbar";
@import "./scss/card";
@import "./scss/breadcrumb";
@import "./scss/pagination";
@import "./scss/badge";
@import "./scss/jumbotron";
@import "./scss/alert";
@import "./scss/progress";
@import "./scss/media";
@import "./scss/list-group";
@import "./scss/close";
@import "./scss/modal";
@import "./scss/tooltip";
@import "./scss/popover";
@import "./scss/carousel";
@import "./scss/utilities";
@import "./scss/print";

html, body {
  min-width: 100%;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  @include transition(.3s);
}

p {
  &.small {
    font-size: $font-size-sm;
  }
  &.x-small {
    line-height: 13px;
    margin: 0;
    font-size: $font-size-sx;
    font-weight: $font-weight-bold;
    color: rgba($white, .5);
  }
}

h1 {
  font-weight: $font-weight-bold;
}

h3 {
  margin-bottom: .625rem;
}

h5 {
  margin-top: 22px;
  margin-bottom: 10px;
  font-weight: $font-weight-semi-bold;
}

input[type="text"], textarea, .form-control {
  background: $niagara;
  border: 2px solid $gray-900;
  color: $gray-900;
  font-size: $font-size-lg;
  font-weight: $font-weight-medium;
  border-radius: 0;
  &:focus {
    color: $gray-900;
    background-color: $niagara;
    border-color: rgba($gray-900, .3);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($niagara, 0.5)
  }
}

textarea {
  height: 80px;
  resize: none;
}

::placeholder {
  color: $gray-900;
  opacity: 1;
  font-weight: $font-weight-medium;
}

.btn-primary {
  font-weight: $font-weight-bold;
  @include transition(.3s);
  background-color: $gray-900;
  border: 1px solid $gray-500;
  border-radius: 8px;
  [class^="icon-"], [class*=" icon-"] {
    margin-right: 10px;
    font-size: $font-size-icon;
    display: none;
  }
  &:hover {
    background-color: darken($gray-900, .1);
  }
  &.default {
    .icon-web {
      display: inline-block;
    }
  }
  &.chrome {
    .icon-chrome {
      display: inline-block;
    }
  }
  &.safari {
    .icon-safari {
      display: inline-block;
    }
  }
  &.edge {
    .icon-edge {
      display: inline-block;
    }
  }
  &.opera {
    .icon-opera {
      display: inline-block;
    }
  }
  &.firefox {
    .icon-firefox {
      display: inline-block;
    }
  }
}

.btn-secondary {
  padding: 0.375rem 1.85rem;
  border-radius: 22px;
}

.btn-app {
  display: inline-flex;
  flex-direction: column;
  margin-left: 20px;
  img {
    height: 40px;
  }
  span {
    line-height: 13px;
    margin-top: 10px;
    font-size: $font-size-sm;
    color: rgba($gray-900, .5);
    font-weight: $font-weight-bold;
    font-family: 'Helvetica', 'Arial', sans-serif;
  }
  &:hover {
    text-decoration: none;
  }
}

.navbar {
  box-shadow: 0 0 0 rgba($black, 0);
  @include transition(.3s);
  &.loaded {
    @include top-to-bottom-fade();
  }
  .navbar-brand img {
    max-width: 155px;
    pointer-events: none;
    @include transition(.3s);
  }
  .navbar-nav {
    .nav-link {
      font-size: $font-size-sm;
      font-weight: $font-weight-black;
      color: $black;
    }
  }
  .navbar-toggler {
    min-height: 36px;
    outline: none;
    cursor: pointer;
    border-color: $gray-900;
  }
}

.animated-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.animated-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.animated-icon span {
  background: $gray-900;
}

.animated-icon span:nth-child(1) {
  top: 0;
}

.animated-icon span:nth-child(2) {
  top: 9px;
}

.animated-icon span:nth-child(3) {
  top: 18px;
}

.navbar-toggler:not(.collapsed) .animated-icon span:nth-child(1) {
  top: 9px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.navbar-toggler:not(.collapsed) .animated-icon span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.navbar-toggler:not(.collapsed) .animated-icon span:nth-child(3) {
  top: 9px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.scrolled .navbar {
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: 0 2px 4px rgba($black, .21);
  background: $niagara;
  .navbar-brand img {
    max-width: 100px;
  }
}

section {
  &.primary {
    background-color: $primary;
  }
  &.secondary {
    background-color: $gray-900;
    color: $white;
  }
  &.cover {
    padding: 150px 0;
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    &:before {
      width: 100%;
      height: 100%;
      display: block;
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
    }
    h1, p {
      color: $white;
    }
    .btn {
      margin-top: 1rem;
    }
  }
}

#head {
  min-width: 100%;
  min-height: 100vh;
  .container, .container > .row {
    min-height: 100vh;
  }
  img.head-img {
    max-width: 100%;
    max-height: 40vh;
    margin-top: ($navbar-padding-y * 2) + $nav-link-height;
    align-self: center;
  }
  p {
    font-weight: $font-weight-medium;
    font-size: $font-size-lg;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#quality {
  padding: 84px 0 34px 0;
  position: relative;
  &:before {
    width: 100%;
    height: 100%;
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    background: url('../img/quality-bg.png') no-repeat center center;
    background-size: 100% auto;
  }
  .bose-logo {
    width: 198px;
    margin-top: 1rem;
  }
}

#try-free {
  background-image: url('../img/try-free-bg.jpg');
  &:before {
    background: rgba($black, .7);
  }
}

#detail {
  padding: 130px 0 100px 0;
  img {
    align-self: center;
    max-width: 100%;
  }
  ul {
    margin-bottom: 8px;
    padding-left: 17px;
  }
}

#all-business {
  background-image: url('../img/all-business-bg.jpg');
  &:before {
    background: rgba($black, .5);
  }
}

#features {
  padding: 84px 0 34px 0;
  overflow: hidden;
  h1, h1 + p {
    padding-left: 42px;
  }
  img {
    max-width: 100%;
    align-self: flex-end;
  }
  p.has-icon {
    padding-left: 42px;
    margin-bottom: 1.875rem;
    position: relative;
    &:first-child {
      margin-top: 68px;
    }
    i {
      font-size: 32px;
      position: absolute;
      top: 0;
      left: 0;
      color: $primary;
    }
  }
}

#feature-list {
  padding: 105px 0;
  .col-xl-6 {
    &:nth-child(1), &:nth-child(2) {
      .icon-circle {
        margin-top: 0;
      }
    }
  }
  .icon-circle {
    width: 64px;
    height: 64px;
    margin-top: 40px;
    border-radius: 32px;
    background-color: $gray-900;
    img {
      width: 40%;
    }
  }
  h2 {
    margin: 20px 0 0 0;
  }
  p {
    margin: 10px 0 0 0;
  }
}

#guide {
  padding: 78px 0 82px 0;
  .flex-xl-row:nth-child(2) {
    padding-bottom: 10px;
  }
  img {
    &.audio {
      width: 125px;
    }
    &.cable {
      width: 80%;
      margin-bottom: 16px;
    }
    &.device {
      width: 169px;
      margin: 10px 0;
    }
  }
}

#app-links {
  padding: 100px 0;
  .logo-img {
    width: 128px;
    align-self: center;
  }
  .btn-container {
    margin-top: 40px;
    .btn-app span {
      color: rgba($white, .5);
    }
  }
}

#footer {
  padding: 65px 0;
  .logo {
    width: 128px;
    margin-bottom: 30px;
  }
  .mail-link {
    margin-bottom: 8px;
    display: inline-block;
    color: $gray-900;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    li {
      list-style-type: none;
    }
    &.social {
      li {
        width: 30px;
        height: 30px;
        margin-right: 20px;
        a {
          width: 30px;
          height: 30px;
          display: inline-block;
          img {
            width: 100%;
          }
        }
      }
    }
    &.brands {
      li {
        width: 150px;
        margin-right: 30px;
        img {
          width: 100%;
        }
        &:last-child {
          width: 135px;
          margin: 0;
        }
      }
    }
  }
  h4 {
    line-height: 30px;
    margin-bottom: 25px;
    font-size: 1.5rem;
  }
  .btn {
    border-radius: 22px;
  }

  ul.logos {
    img {
      height: 64px;
      width: 64px;
    }

    position: relative;
    left: -14px;
  }

  .company {
    p {
      margin: 0;
      font-size: 1em;

      &.small {
        font-size: 0.9em;
      }

      &.smaller {
        font-size: 0.8em;
      }
    }

    a {
      margin-top: 8px;
    }
  }

  ul.terms {
    margin-bottom: 8px;
    font-size: 0.8em;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      flex-direction: row;

      * {
        order: 0;
      }

      p {
        margin: 0;
        padding: 0;
      }

      a {
        color: $black;
        margin-left: 4px;
        margin-right: 4px;
        &:hover {
          text-underline: $black;
        }
      }
    }
  }
}

#plans {
  padding: 80px 0;
  img {
    width: 155px;
    margin-top: 40px;
  }
  h2 {
    line-height: 48px;
    margin-top: 62px;
    font-size: ($font-size-base * 2.5);
    color: $black;
  }
}

#plan-list {
  margin-top: 40px;
  [class^="col-"], [class*=" col-"] {
    padding-left: 0;
    padding-right: 0;
  }
  .plan-card {
    padding-bottom: 65px;
    border-radius: 13px;
    box-shadow: 0 5px 30px rgba($black, .05);
    @include radial-basic-bg;
    h3 {
      line-height: 56px;
      padding-top: 55px;
      padding-bottom: 30px;
      font-size: ($font-size-base * 3);
      font-weight: $font-weight-bold;
      border-radius: 13px;
      overflow: hidden;
    }
    p {
      margin: 0;
      font-family: 'Helvetica', 'Arial', sans-serif;
      font-weight: $font-weight-bold;
      color: $white;
      &.old-price {
        line-height: 28px;
        font-size: ($font-size-base * 1.5);
        text-decoration: line-through;
        opacity: .5;
      }
      &.price {
        height: 115px;
        line-height: 120px;
        font-size: ($font-size-base * 6);
        small {
          font-size: ($font-size-base * 2.25);
        }
      }
      &.duration {
        margin-bottom: 80px;
        line-height: $font-size-lg;
        font-size: $font-size-lg;
      }
      &.features {
        font-size: 10px;
        color: $white;
      }
    }
    .btn {
      margin-bottom: 14px;
      font-size: ($font-size-base * .812);
      background: $niagara;
      border: none;
      color: $gray-900;
      border-radius: 22px;
    }
    &.recomended {
      width: 110%;
      padding-bottom: 92px;
      margin-left: -5%;
      position: relative;
      z-index: 10;
      color: $white;
      box-shadow: 0 5px 30px rgba($black, .3);
      @include radial-recomended-bg;
      h3 {
        padding-top: 78px;
        color: $gray-900;
        font-size: ($font-size-base * 3.312);
      }
      p {
        color: $gray-900;
        &.duration {
          margin-bottom: 62px;
        }
        &.favour {
          margin-bottom: 40px;
        }
      }
      .btn {
        background: $gray-900;
        color: $niagara;
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba($gray-900, 0.5)
        }
      }
    }
  }
}

#page-sub-header {
  background-color: $primary;
  color: $body-color;
  padding-top: 24px;

  img {
    height: 60px;
  }
}

#page-sub-footer {
  background-color: $primary;
  color: $body-color;
  padding-top: 24px;

  .logo {
    height: 40px;
  }

  .company {
    p {
      margin: 0;
      font-size: 1em;

      &.small {
        font-size: 0.9em;
      }

      &.smaller {
        font-size: 0.8em;
      }
    }

    a {
      margin-top: 8px;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  ul.brands {
    display: flex;
    flex-direction: row;

    li {
      width: 100px;
      margin-right: 16px;

      img {
        width: 100%;
      }
    }
  }

  ul.logos {
    display: flex;
    flex-direction: row;

    img {
      height: 64px;
      width: 64px;
    }

    position: relative;
    left: -14px;
  }

  ul.terms {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    font-size: 0.8em;

    li {
      display: flex;
      flex-direction: row;

      * {
        order: 0;
      }

      p {
        margin: 0;
        padding: 0;
      }

      a {
        color: $black;
        margin-left: 4px;
        margin-right: 4px;
        &:hover {
          text-underline: $black;
        }
      }
    }
  }
}

#page-privacy, #page-terms {
  background-color: $primary;
  color: $body-color;
  padding: 30px 0;

  section {
    margin-bottom: 16px;

    h1 {
      font-size: 1.4em;
    }

    p {
      font-size: 0.9em;
      margin: 0;
      padding: 0;
      white-space: pre-wrap;
    }

    &#section-1 {
      margin-bottom: 32px;
      h1 {
        font-size: 2em;
      }
    }
  }
}

@media all and (min-height: 1366px) {

  h1 {
    margin-top: 20px;
  }

  #head {
    img.head-img {
      margin-top: 12vh;
    }
  }

  #detail {
    h1 {
      margin-top: 0;
      font-size: ($font-size-base * 1.75);
    }
    img {
      margin-top: 60px;
    }
  }

  #features {
    h1 {
      margin-top: 0;
      font-size: ($font-size-base * 1.75);
    }
    p.has-icon:first-child {
      margin-top: 40px;
    }
  }

  .btn-container {
    margin-top: 40px;
  }

}

@media all and (max-width: 1024px) {

  .navbar {
    padding-top: 7px;
    padding-bottom: 7px;
    background: rgba(255, 255, 255, 0.85);
    .navbar-brand img {
      max-width: 100px;
    }
  }

  section {
    padding-bottom: 60px;
  }

  #head {
    min-height: 0;
    img.head-img {
      max-height: unset;
      margin-top: 60px;
    }
  }

  #detail {
    img {
      margin-top: 20%;
    }
  }

  #features {
    padding-bottom: 0;
  }

  #footer {
    height: auto;
  }

}

@media all and (max-width: 991px) {

  body {
    font-size: ($font-size-base * .8);
  }

  h1 {
    font-size: ($font-size-base * 1.4);
  }

  h5 {
    font-size: ($font-size-base * 1.1);
  }

  .navbar-collapse {
    padding-top: 40px;
  }

  #plans {
    #plan-list {
      h3 {
        font-size: ($font-size-base * 2.625);
      }
      p {
        &.price {
          font-size: ($font-size-base * 4);
        }
      }
    }
  }

}

@media all and (max-width: 768px) {

  .hide-on-sm {
    display: none !important;
  }

  #detail {
    img {
      max-width: 70%;
      margin-top: 40px;
    }
  }

  #quality {
    .bose-logo {
      margin-bottom: 30px;
    }
  }

  #features {
    .top-margined {
      flex-direction: column-reverse;
      margin-top: 0;
      img {
        max-width: 50%;
        margin-top: 20px;
      }
    }
    p.has-icon:first-child {
      margin-top: 20px;
    }
    p.has-icon:last-child {
      margin-bottom: 50px;
    }
  }

  #guide {
    img.cable {
      margin: 110px 0;
      transform: rotate(90deg);
    }
    .audio-desc, .device-desc {
      min-height: 155px;
    }
    .download-desc {
      min-height: 212px;
    }
  }

  #feature-list {
    .col-xl-6 {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #plans {
    .plan-card {
      margin-bottom: 40px;
    }
  }

  #footer {
    ul {
      margin-bottom: 30px;
    }
    .copy {
      margin-bottom: 80px;
    }
  }

}

@media all and (max-width: 575px) {

  .hide-on-xs {
    display: none !important;
  }

  #head {
    img.head-img {
      margin-top: 0;
    }
  }

  section {
    &.secondary {
      padding-top: 75px;
      padding-bottom: 60px;
    }
    &.primary {
      padding-top: 75px;
    }
  }

  .btn-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      margin-bottom: 18px;
      margin-left: 0;
    }
  }

  #quality {
    text-align: center;
    img {
      max-width: 100%;
    }
  }

  #guide {
    img.cable {
      width: 38%;
    }
    .btn {
      margin-top: 30px;
    }
  }

  #plan-list {
    .plan-card.recomended {
      width: 100%;
      margin-left: 0;
    }
  }

}

@media all and (max-width: 375px) {
}
